import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./App.css";
import { Security, ImplicitCallback, SecureRoute } from "@okta/okta-react";
import { BrowserRouter as Router, Route } from "react-router";
import Login from "./components/auth/Login";
import Nav from "./components/nav/nav";
import PortalMain from "./components/portalMain/portalMain";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Login />
        <SecureRoute exact={true} component={Nav} />
        <SecureRoute path="/portal" exact={true} component={PortalMain} />
        <Route path="/implicit/callback" component={ImplicitCallback} />
      </div>
    );
  }
}

export default App;
