let configuration;
let issuer = "https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7";
let client_id = "0oa5f9441nXpWHf9T2p7";

if(process.env.NODE_ENV === 'development') {
      configuration = 'https://api-eapps-identityportal-prod.ase-eapps-prod-001.p.azurewebsites.net/'
}
else if(process.env.NODE_ENV === 'production') {
    //  configuration = 'https://identityportalapi.chlaase.p.azurewebsites.net'
      configuration = 'https://api-eapps-identityportal-prod.ase-eapps-prod-001.p.azurewebsites.net/'
}
export {configuration, issuer, client_id}
