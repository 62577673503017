import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table } from "react-bootstrap";
import "./staffInfo.css";

export default class StaffInfo extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      modalShow: false
    };

  }

  validateInput = (input) => {
    const {validateInput} = this.props;
    let result = validateInput(input);
    return result;
  }

 

  render() {
    const { generalDetails } = this.props;
    return (
      <div className="mainStaff">
        <h2 id="heading">
          {generalDetails.employeeFirstName +
            " " +
            generalDetails.employeeLastName}
        </h2>
        <span id="hr_line" />
        <div className="container">
          <div className="inner_info row ">
            <div className="col-md-4 col-12 picture">
            {generalDetails.image === null ? (
              <img
                className="image"
                src={require('../../assets/no-image.png')}
              />
            ):  <img
                className="image"
                src={`data:image/jpeg;base64,${generalDetails.image}`}
              /> }
             
            </div>

            <Table
              bordered
              hover
              size="md"
              className="table col-md-8 col-12 detail_employee table-striped"
              id="general_detail"
            >
              <tbody>
                <tr>
                  <th>Employee ID</th>
                  <td>{this.validateInput(generalDetails.employeeId)}</td>
                </tr>
                <tr>
                  <th>Badge Number</th>
                  <td>{this.validateInput(generalDetails.badgeNumber)}</td>
                </tr>
                <tr>
                  <th>Username</th>
                  <td>{this.validateInput(generalDetails.username)}</td>
                </tr>
                <tr>
                  <th>Department</th>
                  <td>{this.validateInput(generalDetails.department)}</td>
                </tr>
                <tr>
                  <th>Office Phone</th>
                  <td>{this.validateInput(generalDetails.officePhone)}</td>
                </tr>
                <tr>
                  <th>Manager</th>
                  <td>{this.validateInput(generalDetails.manager)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
