import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Headers,
  Table,
  Collapse,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import "./sensitiveInfo.css";
import { withAuth } from "@okta/okta-react";
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: inline;
`;

export default class SensitiveInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetAnswer: false,
      resetCode: false,
      validityMFA: false,
      validityQues: false,
    };
  }

  validateInput = (input) => {
    const { validateInput } = this.props;
    let result = validateInput(input);
    return result;
  };

  reset = () => {
    this.setState({ resetAnswer: true, validityQues: true });
  };

  resetCode = () => {
    this.setState({ validityMFA: true, resetCode: true });
  };

  handleChangeCode = (e) => {
    if (e.target.value === "") {
      this.setState({ validityMFA: true, resetCode: true });
    }
  };

  handleChangeAnswer = (e) => {
    if (e.target.value === "") {
      this.setState({ resetAnswer: true, validityQues: true });
    }
  };

  verifyAnswer = (e) => {
    e.preventDefault();
    this.setState({ resetAnswer: false, validityQues: false });

    let data = {};
    let answer = this.refs.answer.value;
    data.answer = answer;

    const { verifyAnswer } = this.props;
    verifyAnswer(data);
    //this.refs.myForm.reset();
  };

  verifyCode = (e) => {
    e.preventDefault();
    this.setState({ resetCode: false, validityMFA: false });

    let data = {};
    let mfa_number = this.refs.mfa_number.value;
    data.code = mfa_number;

    const { verifyCode } = this.props;
    verifyCode(data);
    // this.refs.myForm1.reset();
  };
  render() {
    let answerValid, codeValid, error, errorCode;
    const {
      sensitiveDetails,
      isAnswerValid,
      securityAnswerLoaded,
      isCodeValid,
      securityCodeLoaded,
      isValidAnswerError,
      isValidCodeError,
    } = this.props;
    if (isValidAnswerError) {
      answerValid = <span style={{ fontWeight: "bold" }}>Bad request!</span>;
    } else if (securityAnswerLoaded === false) {
      answerValid = (
        <span
          style={{
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
          <BeatLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#00B4E8"}
            loading={this.props.securityAnswerLoaded === false}
          />
        </span>
      );
    } else if (securityAnswerLoaded === true && isAnswerValid) {
      answerValid = <i className="fas fa-check"></i>;
    } else if (securityAnswerLoaded === true && isAnswerValid === false) {
      answerValid = <i className="fas fa-times"></i>;
    }

    // valid MFA code
    if (isValidCodeError) {
      errorCode = <span style={{ fontWeight: "bold" }}>Bad request!</span>;
    } else if (securityCodeLoaded === false) {
      codeValid = (
        <span
          style={{
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
          <BeatLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#00B4E8"}
            loading={this.props.securityCodeLoaded === false}
          />
        </span>
      );
    } else if (securityCodeLoaded === true && isCodeValid) {
      codeValid = <i className="fas fa-check"></i>;
    } else if (securityCodeLoaded === true && isCodeValid === false) {
      codeValid = <i className="fas fa-times"></i>;
    }

    return (
      <Table
        bordered
        hover
        size="md"
        className="table col-md-8 col-12 detail_employee table-striped"
        id="sensitive_tr"
      >
        <tbody id="tbody">
          <tr>
            <th>Security Question</th>
            <td>{this.validateInput(sensitiveDetails.securityQuestion)}</td>
          </tr>

          <tr>
            <th>Security Answer</th>
            <td>
              <form
                id="verify_answer"
                ref="myForm"
                onSubmit={this.verifyAnswer}
              >
                <input
                  type="password"
                  className="input-box"
                  onChange={this.handleChangeAnswer}
                  name="answer"
                  disabled={
                    this.props.sensitiveDetails.securityQuestion ===
                    "Not available"
                      ? true
                      : false
                  }
                  ref="answer"
                  placeholder="Enter security answer"
                  style={{
                    border:
                      isAnswerValid === false && !this.state.validityQues
                        ? "1px solid red"
                        : null,
                  }}
                  title="Password is case insesitive."
                />
                <button
                  className="close-icon"
                  type="reset"
                  onClick={this.reset}
                ></button>
                <button type="submit" form="verify_answer" id="btn_verify">
                  Verify
                </button>
                {!this.state.resetAnswer
                  ? answerValid
                  : this.refs.answer.length > 1}
                {error}
              </form>
            </td>
          </tr>

          <tr>
            <th>OKTA MFA Number</th>
            <td>
              <form id="verify_code" ref="myForm1" onSubmit={this.verifyCode}>
                <input
                  type="text"
                  onChange={this.handleChangeCode}
                  name="mfa_number"
                  className="text-box"
                  ref="mfa_number"
                  style={{
                    border:
                      isCodeValid === false && !this.state.validityMFA
                        ? "1px solid red"
                        : null,
                  }}
                  placeholder="OKTA MFA number"
                />
                <button
                  className="close-icon"
                  type="reset"
                  onClick={this.resetCode}
                ></button>
                <button type="submit" form="verify_code" id="btn_verify_code">
                  Verify
                </button>
                {!this.state.resetCode
                  ? codeValid
                  : this.refs.mfa_number.length > 1}

                {errorCode}
              </form>
            </td>
          </tr>

          <tr>
            <th>Last 4 SSN</th>
            <td>{this.validateInput(sensitiveDetails.ssn)}</td>
          </tr>
          <tr>
            <th>Home Address</th>
            <td>{this.validateInput(sensitiveDetails.homeAddress)}</td>
          </tr>
          <tr>
            <th>Emergency Contact</th>
            <td>{this.validateInput(sensitiveDetails.emergencyContact)}</td>
          </tr>
          <tr>
            <th>Cell Phone</th>
            <td>{this.validateInput(sensitiveDetails.mobileNumber)}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}
