import React from "react";
import { Modal } from "react-bootstrap";
import "./printPopup.css";

class PrintPopup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      modalShow: false
    };
  }

  // on click on yes-continue this method is called
  onContinue = input => {
    const { updateshowsensitiveinfo } = this.props;
    updateshowsensitiveinfo(input);
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body closeButton>
          &nbsp;
          <h2 id="title">Warning</h2>
          <br />
          <p>
          You are about to view sensitive information for this employee. Your activity will be logged. Are you sure you want to proceed?
          </p>
          &nbsp;
          <div className="center_btn">
            <button
              onClick={() => this.onContinue(false)}
              className="btn_inner  btn btn-sm "
              id="cancel"
            >
              No, Cancel
            </button>
            <button
              className="btn_inner  btn btn-sm "
              id="continue"
              onClick={() => this.onContinue(true)}
            >
              Yes, Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PrintPopup;
