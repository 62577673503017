import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Collapse, Card } from "react-bootstrap";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import "./potalMain.css";
import StaffInfo from "../staffInfo/staffInfo";
import { withAuth } from "@okta/okta-react";
import SensitiveInfo from "../sensitiveInfo/sensitiveInfo";
import PrintPopup from "../printPopup/printPopup";
import { configuration } from "../../environment/environment";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const NOT_AVAILABLE = "Not available";
const DOMAIN = configuration;
export default withAuth(
  class PortalMain extends Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        modalShow: false,
        isShow: false,
        openPopUp: false,
        showSensitiveInfo: false,
        isLoaded: false,
        isSensitiveInfoLoaded: false,
        generalDetails: {},
        sensitiveDetails: {},
        iTrackNumber: "",
        showData: false,
        error: false,
        sensitiveError: false,
        isAnswerValid: null,
        securityAnswerLoaded: null,
        isCodeValid: null,
        securityCodeLoaded: null,
        isValidAnswerError: false,
        isValidCodeError: false,
      };
      this.updateshowsensitiveinfo = this.updateshowsensitiveinfo.bind(this);
      this.showData = this.showData.bind(this);
      this.hideData = this.hideData.bind(this);
      this.verifyAnswer = this.verifyAnswer.bind(this);
      this.verifyCode = this.verifyCode.bind(this);
    }

    // fetching general detail API
    getGeneralDetails = async (iTrack) => {
      const url = `${DOMAIN}/api/identitydetails/${iTrack}`;
      axios
        .get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: "Bearer " + (await this.props.auth.getAccessToken()),
          },
        })
        .then(
          (result) => {
            result = result.data;
            if (this.refs.text.value !== "") {
              this.setState({
                generalDetails: result,
                open: true,
                error: false,
                isLoaded: true,
              });
              this.showData();
            }
          },
          (error) => {
            this.setState({ error: true, isLoaded: false });
          }
        );
    };

    // fetching sensitive detail API
    getSensitiveDetails = async (iTrack) => {
      const url = `${DOMAIN}/api/identitydetails/sensitive/${iTrack}`;
      axios
        .get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Pragma: "no-cache",
            Authorization: "Bearer " + (await this.props.auth.getAccessToken()),
          },
        })
        .then(
          (result) => {
            result = result.data;

            this.setState({
              sensitiveDetails: result,
              showSensitiveInfo: true,
              isSensitiveInfoLoaded: true,
              open: true,
              sensitiveError: false,
            });
            this.showData();
          },
          (error) => {
            this.setState({
              isSensitiveInfoLoaded: false,
              sensitiveError: true,
            });
          }
        );
    };

    //POST request verify security answer
    async verifyAnswer(data) {
      this.setState({ securityAnswerLoaded: false, isAnswerValid: null });
      const url = `${DOMAIN}/api/identitydetails/verifyanswer`;

      const headers = {
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: "Bearer " + (await this.props.auth.getAccessToken()),
      };
      data.username = this.state.generalDetails.username;
      axios
        .post(url, data, {
          headers: headers,
        })
        .then((result) => {
          result = result.data.isValid;
          this.setState({
            isAnswerValid: result,
            securityAnswerLoaded: true,
            isValidAnswerError: false,
          });
        })
        .catch((err) =>
          this.setState({
            securityAnswerLoaded: false,
            isValidAnswerError: true,
          })
        );
    }

    //POST request verify security code
    async verifyCode(data) {
      this.setState({ securityCodeLoaded: false, isCodeValid: null });
      const url = `${DOMAIN}/api/identitydetails/verifycode`;

      const headers = {
        "Content-Type": "application/json",
        Pragma: "no-cache",
        Authorization: "Bearer " + (await this.props.auth.getAccessToken()),
      };
      data.username = this.state.generalDetails.username;
      axios
        .post(url, data, {
          headers: headers,
        })
        .then((result) => {
          result = result.data.isValid;
          this.setState({
            isCodeValid: result,
            securityCodeLoaded: true,
            isValidCodeError: false,
          });
        })
        .catch((err) =>
          this.setState({ securityCodeLoaded: false, isValidCodeError: true })
        );
    }

    // on typing input search bar
    handleChange = (e, target) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.onClick();
      }
      this.setState({ iTrackNumber: e.target.value });
    };

    // conditional method on showing Data
    showData = () => {
      this.setState({ showData: true });
    };

    // on Click on clear search, hides the data
    hideData = () => {
      this.setState({
        showData: false,
        open: false,
        showSensitiveInfo: false,
        securityAnswerLoaded: null,
        securityCodeLoaded: null,
        isValidAnswerError: false,
        isValidCodeError: false,
      });
      this.refs.text.value = "";
    };

    // on click on search button calling the api and passing the itrack number
    onClick = () => {
      this.setState({
        open: true,
        sensitiveError: false,
        error: false,
        isLoaded: false,
        isSensitiveInfoLoaded: false,
        showSensitiveInfo: false,
        isValidAnswerError: false,
      });
      if (this.refs.text.value === null) {
        return <div>ERROR</div>;
      } else {
        this.getGeneralDetails(this.state.iTrackNumber);
      }
    };

    validateInput = (input) => {
      if (input === null || input === "") {
        return NOT_AVAILABLE;
      } else {
        return input;
      }
    };

    updateshowsensitiveinfo(input) {
      this.setState({
        showSensitiveInfo: input,
        securityAnswerLoaded: null,
        securityCodeLoaded: null,
        isValidAnswerError: false,
        isValidCodeError: false,
      });
      if (input === true) {
        this.getSensitiveDetails(this.state.iTrackNumber);
      }
    }

    // on click on popup this method will change the state to true and popup will appear
    popUpToggle = () => {
      this.setState({ modalShow: true });
    };

    render() {
      let information, error, sensitiveInfomation;
      error = (
        <div>
          <h1 id="error_message">Please enter valid iTrack number.</h1>
        </div>
      );
      if (!this.state.isLoaded) {
        information = (
          <div
            style={{
              textAlign: "center",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={15}
              color={"#00B4E8"}
              loading={!this.state.isLoaded}
            />
          </div>
        );
      } else {
        information = (
          <StaffInfo
            generalDetails={this.state.generalDetails}
            validateInput={this.validateInput}
          />
        );
      }
      if (!this.state.isSensitiveInfoLoaded) {
        sensitiveInfomation = (
          <div
            style={{
              textAlign: "center",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={15}
              color={"#00B4E8"}
              loading={!this.state.isSensitiveInfoLoaded}
            />
          </div>
        );
      } else {
        sensitiveInfomation = (
          <SensitiveInfo
            validateInput={this.validateInput}
            isValidAnswerError={this.state.isValidAnswerError}
            sensitiveDetails={this.state.sensitiveDetails}
            verifyAnswer={this.verifyAnswer}
            verifyCode={this.verifyCode}
            isAnswerValid={this.state.isAnswerValid}
            isCodeValid={this.state.isCodeValid}
            securityAnswerLoaded={this.state.securityAnswerLoaded}
            securityCodeLoaded={this.state.securityCodeLoaded}
            isSensitiveInfoLoaded={this.state.isSensitiveInfoLoaded}
            isValidCodeError={this.state.isValidCodeError}
          />
        );
      }

      const { open } = this.state;
      let modalClose = () => this.setState({ modalShow: false });
      let button;
      if (this.state.showSensitiveInfo) {
        button = (
          <button
            size="sm"
            onClick={() => this.updateshowsensitiveinfo(false)}
            aria-controls="menuDropdown"
            aria-expanded={open}
            className="btn-lg btnShowInfo"
            id="dropdown-basic"
          >
            <span className="name">Hide Sensitive Information</span>
            <i className="fa fa-angle-up plusSign" />
          </button>
        );
      } else {
        button = (
          <button
            size="sm"
            onClick={this.popUpToggle}
            aria-controls="menuDropdown"
            aria-expanded={open}
            className="btn-lg btnShowInfo"
            id="dropdown-basic"
          >
            <span className="name">Show Sensitive Information</span>
            <i className="fa fa-angle-down plusSign" />
          </button>
        );
      }

      return (
        <div className="container">
          <div className=" outer_border" id="outer">
            <div className="input_btn">
              <input
                className="col-md-8 col-12 search"
                type="text"
                placeholder="iTrack Number"
                onKeyUp={this.handleChange}
                ref="text"
              />
              <button
                className="btn_search"
                aria-controls="btn_search_drop"
                aria-expanded={open}
                onClick={this.onClick}
              >
                Search
              </button>
              {this.state.error ? (
                error
              ) : (
                <>
                  <Collapse in={this.state.open}>
                    <div>
                      <div id="btn_search_drop">
                        <br />

                        {information}
                        {this.state.showSensitiveInfo ? (
                          !this.state.sensitiveError ? (
                            sensitiveInfomation
                          ) : (
                            <div>No Sensitive information found..</div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>

                      {this.state.isLoaded ? (
                        <>
                          <div className="show_info_btn">{button}</div>{" "}
                          <button
                            className="clear_search"
                            size="sm"
                            onClick={() => this.hideData()}
                          >
                            Clear Search
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Collapse>
                </>
              )}

              <div id="menuDropdown">
                <PrintPopup
                  show={this.state.modalShow}
                  updateshowsensitiveinfo={this.updateshowsensitiveinfo}
                  onHide={modalClose}
                  getSensitiveDetails={this.getSensitiveDetails}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
