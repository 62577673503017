import React, { Component } from "react";
import "./nav.css";
import Navbar from "react-bootstrap/Navbar";
import { withAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
export default withAuth(
  class Nav extends Component {
    state = { authenticated: null, userinfo: null };

    checkAuthentication = async () => {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated && !this.state.userinfo) {
        const userinfo = await this.props.auth.getUser();
        const accessToken = await this.props.auth.getAccessToken();
       
        this.setState({ authenticated, userinfo });
      }
    };

    async componentDidMount() {
      this.checkAuthentication();
    }

    async componentDidUpdate() {
      this.checkAuthentication();
    }

    login = async () => {
      this.props.auth.login("/");
    };

    logout = async () => {
      this.props.auth.logout("/");
    };

    render() {
      const Token = JSON.parse(localStorage.getItem('okta-token-storage'));
      const button = this.state.authenticated ? (
        <a id="nav_logout" onClick={this.logout}>
          Logout
        </a>
      ) : (
        <></>
      );

      return (
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand className="logo">
            <img src={require("../../assets/logoCHLA.png")} alt="Image" />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <>
            <div className=" w-100 order-3">
              <div className="d-md-flex d-block w-100">
                <div
                  className="mx-auto w-auto justify-content-center"
                  id="navbarNavAltMarkup"
                >
                  {/* <div>
                    <div id="deviceAssess">Device Assessment</div>
                  </div> */}
                </div>
                <div className="nav-menu" id="navMenu">
                  <div id="left">
                    {this.state.userinfo !== null && (
                      <a>{this.state.userinfo.name}</a>
                    )}
                    <span id="span">|</span>
                  </div>
                  <div id="right">
                    {button}
                  </div>
                </div>
              </div>
            </div>
          </>
        </Navbar>
      );
    }
  }
);
