import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Security, ImplicitCallback, SecureRoute } from "@okta/okta-react";
import { BrowserRouter } from "react-router-dom";
import { __esModule } from "@okta/okta-react/dist/Security";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { issuer, client_id } from './environment/environment';

const config = {
    //prod creds
    issuer: issuer,
    redirect_uri: window.location.origin + "/implicit/callback",
    client_id: client_id,
  };
ReactDOM.render(
<BrowserRouter>
    <Security
      issuer={config.issuer}
      client_id={config.client_id}
      redirect_uri={config.redirect_uri}
        >
      <App />
    </Security>
  </BrowserRouter>,
 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
